<template>
	<nav
		class="breadcrumbs-wrapper"
		:class="[{ background }, { dark }]"
		aria-label="breadcrumbs"
	>
		<ol
			@scroll="handleScroll"
			:class="[
				{ scrollable: isScrollable },
				{ 'fade-left': showLeftFade },
				{ 'fade-right': showRightFade },
				{ 'remove-spacing': removeSpacing },
			]"
			ref="breadcrumbsScroll"
		>
			<li v-for="crumb in crumbs">
				<TnIcon
					v-if="type === types.TRUNCATED"
					name="chevron-left"
					size="s"
					aria-hidden="true"
				/>
				<component
					:is="crumb.nodeType"
					:href="crumb.href"
					:to="crumb.to"
					:data-href="crumb.href || crumb.to"
					:class="['crumb', { current: crumb.isCurrent }]"
					:v-bind="$attrs"
					:aria-current="crumb.isCurrent && type !== types.TRUNCATED ? 'location' : 'false'"
				>
					{{ crumb.text }}
				</component>
				<TnIcon
					v-if="type !== types.TRUNCATED && !crumb.isCurrent"
					name="chevron-right"
					size="s"
					aria-hidden="true"
				/>
			</li>
		</ol>
	</nav>
</template>

<script>
import { defineComponent, resolveComponent } from "vue";

import types from "./definitions/types";

/**
 * ## Description
 *
 * TnBreadcrumbs component is used to display an interactive trail of the current page's location/hierarchy,
 * typically at or near the top of the page. This serves as a way to easily navigate to upper levels in the page
 * hierarchy and show users where they're located.
 *
 * The breadcrumbs consists of an `array` containing multiple `crumb` objects.
 * Each `crumb` object has the following properties:
 * ### Required
 * - `text`
 *   - `string` The text to be displayed on the crumb link.
 *
 * #### Choose one
 * - `to`
 *   - `string` A `nuxt`/`router` link for the given crumb (prioritized).
 * - `href`
 *   - `string` A regular URL for the crumb link.
 *
 * You do not need both `to` and `href`, you can choose just one. Both can be present at the same time,
 * but `to` will take priority.
 *
 * ## Rules
 * - There should only be one TnBreadcrumbs component present on the page at any given time.
 * - The types `scrollable` and `truncated` should only be used on mobile viewports. For desktop, only `default` should be used.
 * @displayName TnBreadcrumbs
 */
export default defineComponent({
	name: "TnBreadcrumbs",

	props: {
		/**
		 * An array of `crumb` objects shaping the breadcrumbs.
		 * A `crumb` object consists of `to` and/or `href`, as well as `text`.
		 * @param {[{[to]: string, [href]: string, text: string}]} excludedRoutes An array of excluded routes with `name` and/or `path` and `exact` (default `true`) properties.
		 * @example [{text: "Home", to: "/", href: "/"}, {text: "Page 1", to: "/page-1", href: "/page-1"}]
		 */
		breadcrumbs: {
			type: Array,
			default: () => [],
			validator: (crumbs) => {
				return crumbs.every(
					(crumb) =>
						typeof crumb === "object" &&
						(crumb.hasOwnProperty("to") || crumb.hasOwnProperty("href")) &&
						crumb.hasOwnProperty("text"),
				);
			},
			required: true,
		},
		/**
		 * Enables a background color for the entire breadcrumbs container
		 */
		background: {
			type: Boolean,
			default: false,
		},
		/**
		 * Sets the type for the breadcrumbs
		 * @values default, scrollable, truncated
		 * `scrollable` and `truncated` should only be used on mobile viewports
		 */
		type: {
			type: String,
			default: "default",
			validator: (value) => {
				if (value) return Object.values(types).includes(value.toLowerCase());
				return true;
			},
		},
		/**
		 * The text displayed alongside the back arrow while the `type` is set to `truncated`
		 * @example Go to
		 */
		goToText: {
			type: String,
			default: "Go to",
		},
		/**
		 * Enables dark theme
		 */
		dark: {
			type: Boolean,
			default: false,
		},
		/**
		 * Removes spacing around the component
		 */
		removeSpacing: {
			type: Boolean,
			default: false,
		},
	},
	data: function () {
		return {
			types: types,
			isScrolledLeft: false,
			isScrolledRight: false,
		};
	},

	computed: {
		crumbs() {
			const numberOfCrumbs = this.breadcrumbs.length;
			const mappedCrumbs = this.breadcrumbs.map((crumb, i) => {
				const isCurrent = i === numberOfCrumbs - 1;
				return {
					...crumb,
					nodeType: isCurrent ? "span" : this.nodeType(crumb),
					isCurrent,
				};
			});

			if (this.type === types.TRUNCATED && numberOfCrumbs > 1) {
				const secondToLast = mappedCrumbs[numberOfCrumbs - 2];
				secondToLast.text = `${this.goToText} ${secondToLast.text}`;
				return [secondToLast];
			}

			return mappedCrumbs;
		},
		showLeftFade() {
			return this.isScrollable && !this.isScrolledLeft;
		},
		showRightFade() {
			return this.isScrollable && !this.isScrolledRight;
		},
		isScrollable() {
			return this.type === types.SCROLLABLE;
		},
	},

	mounted() {
		this.update();
	},

	beforeMount() {
		if (this.isScrollable) {
			window.addEventListener(
				"orientationchange",
				() => {
					window.addEventListener("resize", this.update, {
						once: true, // Automatically revokes listener after it has been revoked
					});
				},
				true,
			);
		}
	},

	beforeUnmount() {
		if (this.isScrollable) {
			window.removeEventListener("orientationchange", this.update);
		}
	},

	methods: {
		nodeType(crumb) {
			if (crumb.to && this.$nuxt) return resolveComponent("NuxtLink");
			if (crumb.to && this.$router) return "router-link";
			if (crumb.href) return "a";
		},
		handleScroll(target) {
			if (!this.isScrollable) return;

			target = target.target || target;
			const { offsetWidth, scrollWidth, scrollLeft } = target;

			this.isScrolledLeft = scrollLeft === 0;
			this.isScrolledRight = scrollLeft >= scrollWidth - offsetWidth;
		},
		update() {
			if (this.isScrollable) {
				// Align the scrollbar to the right to ensure we show the current page name
				this.$refs.breadcrumbsScroll.scrollLeft = this.$refs.breadcrumbsScroll.scrollWidth;
				this.handleScroll(this.$refs.breadcrumbsScroll);
			}
		},
	},
});
</script>

<style lang="scss" scoped>
@use "@/assets/scss/variables" as variables;
@use "@/assets/global-style/scss/mixins/all" as mixins;

// Color definitions
.breadcrumbs-wrapper {
	--crumb-color: #{variables.$color-neutrals-600-shade};
	--current-crumb-color: #{variables.$color-neutrals-900-shade};
	--active-crumb-color: #{variables.$color-neutrals-800-shade};
	--focus-crumb-color: #{variables.$color-cta-focus};
	--background-color: #{variables.$color-neutrals-25-tint};

	&.dark {
		--crumb-color: #{variables.$color-neutrals-300-tint};
		--current-crumb-color: #{variables.$color-neutrals-25-tint};
		--active-crumb-color: #{variables.$color-neutrals-100-tint};
		--focus-crumb-color: #{variables.$color-cta-dark-focus};
		--background-color: #{variables.$color-primary-dark};
	}
}

// Styling
.breadcrumbs-wrapper {
	color: var(--crumb-color);

	&.background {
		background-color: var(--background-color);
	}

	ol {
		margin: 0;
		list-style: none;
		display: flex;
		align-items: center;
		gap: 2px;
		padding: 0 8px;

		&.scrollable {
			--left-fade: 0px; // Must be defined as px due to dynamic transformation
			--right-fade: 0px; // Must be defined as px due to dynamic transformation

			overflow-x: auto;

			// Hide scrollbar
			-ms-overflow-style: none; // IE and Edge
			scrollbar-width: none; // Firefox

			&::-webkit-scrollbar {
				display: none; // Chrome, Safari and Opera
			}
		}

		&.fade-left {
			--left-fade: 48px;
		}

		&.fade-right {
			--right-fade: 48px;
		}

		&.fade-left,
		&.fade-right {
			mask-image: linear-gradient(
				to right,
				transparent 0,
				black var(--left-fade),
				black calc(100% - var(--right-fade)),
				transparent 100%
			);
		}

		&.remove-spacing {
			padding: 0;

			.crumb {
				margin-top: 6px;
				margin-bottom: 6px;
			}

			li:first-of-type .crumb {
				margin-left: 0;
			}

			li:last-of-type .crumb {
				margin-right: 0;
			}
		}

		li {
			display: flex;
			align-items: center;
			white-space: nowrap;
		}
	}

	.crumb {
		font-family: variables.$font-family-telenor-ui;
		text-decoration: none;
		height: 100%;
		color: inherit;
		font-size: 12px;
		line-height: 16px;
		margin: 16px 8px;

		&:active,
		&:hover {
			&:not(.current) {
				box-shadow: inset 0 -1px 0 0 var(--crumb-color);
				cursor: pointer;
			}
		}

		&:active {
			color: var(--active-crumb-color);
		}

		&:focus {
			border-radius: 4px;
			outline: 2px solid var(--focus-crumb-color);
			outline-offset: 4px;
		}

		&.current {
			color: var(--current-crumb-color);
		}

		@include mixins.breakpoint(mobile) {
			margin-top: 0;
			margin-bottom: 0;
			padding-top: 16px;
			padding-bottom: 16px;

			&:active,
			&:hover {
				&:not(.current) {
					box-shadow: none;
				}
			}
		}
	}

	[name="chevron-left"] {
		margin-left: 8px;
	}
}
</style>
